import React from 'react'
import { createRoot } from 'react-dom/client'
import { App } from './views/App'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { persistor, store } from './redux'
import { PersistGate } from 'redux-persist/integration/react'

// if (module.hot) module.hot.accept()

const rootElement = document.getElementById('app')
const root = createRoot(rootElement as HTMLElement)

root.render(<>

  {/* <React.StrictMode> */}
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>
  {/* </React.StrictMode> */}

</>)

console.log('process.env.API_URL', process.env.API_URL)

/**
 * Quick fix to prevent double-click text selection.
 * @see https://stackoverflow.com/a/43321596
 */
document.addEventListener('mousedown', (event) => event.detail > 1 && event.preventDefault(), false)

/**
 * Go fullscreen when rotating the device in landscape.
 */
// screen.orientation.addEventListener('change', function() {
//   if (screen.orientation.type.startsWith('landscape')) {
//     document.body.requestFullscreen()
//   } else {
//     document.exitFullscreen()
//   }
// })
