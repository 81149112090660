import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { LoadingStatus } from '../utils'


/* --------------------------------------------------

    STATE

-------------------------------------------------- */

export interface GlobalState {
  loadingStatus:string
  latestGridId:number
  gridRanges:Array<Array<number>>
}

const initialState:GlobalState = {
  loadingStatus: LoadingStatus.IDLE,
  latestGridId: 0,
  gridRanges: [],
}


/* --------------------------------------------------

    THUNKS

-------------------------------------------------- */

/* -------------------------
    FETCH MAIN JSON
------------------------- */

export const fetchMainJSON = createAsyncThunk(
  'global/fetchMainJSON',
  () => fetch(`${process.env.API_URL}/index.json`).then((response) => {
    if (response.status !== 200) throw 'Error'
    return response.json()
  }),
)

const fetchMainJSON_reducer = (builder) => builder
  .addCase(fetchMainJSON.pending, (state, action) => {
    state.loadingStatus = LoadingStatus.LOADING
  })
  .addCase(fetchMainJSON.rejected, (state, action) => {
    state.loadingStatus = LoadingStatus.ERROR
  })
  .addCase(fetchMainJSON.fulfilled, (state, action) => {
    state.latestGridId = action.payload.latestGridId
    state.gridRanges = action.payload.gridRanges
    state.loadingStatus = LoadingStatus.LOADED
  })


/* --------------------------------------------------

    SLICE

-------------------------------------------------- */

export const counterSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {},
  extraReducers: builder => {
    fetchMainJSON_reducer(builder)
  },
})

export default counterSlice.reducer
