import './Grid.scss'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import update from 'immutability-helper'
import { arrayOfInts, shuffleArray } from '../../utils'
import { GridGroup } from '../../utils/interfaces'
import { GridCell } from './GridCell'

interface Props {
  groups:Array<GridGroup>
  foundGroupIds:Array<number>
  onGroupFound:(groupId) => void
  onFailedAttempt:() => void
}

interface GridClueToRender {
  uid:number
  label:string
  groupIdx:number
  gridX:number
  gridY:number
  selected:boolean
  found:boolean
  errorCount:number
}

function getCoordByUid(uid) {
  return {
    groupIdx: Math.floor(uid / 4),
    clueIdx: uid % 4,
  }
}

const DEFAULT_CELL_WIDTH = 110
const DEFAULT_FONT_SIZE = 16
const MIN_FONT_SIZE = 14

export const Grid = ({ groups, foundGroupIds, onGroupFound, onFailedAttempt }:Props) => {
  const ref = useRef<HTMLElement>(null)

  /* -------------------------
      STATE
  ------------------------- */

  const [randomization, setRandomization] = useState<Array<number>>(shuffleArray(arrayOfInts(16)))
  const [selectedClueIds, setSelectedClueIds] = useState<Array<number>>([])
  const [fontSize, setFontSize] = useState<string>(`${DEFAULT_FONT_SIZE}px`)
  const [cluesToRender, setCluesToRender] = useState<Array<Readonly<GridClueToRender>>>([])

  /* -------------------------
      RESIZE (FONT ADJUST)
  ------------------------- */

  const invalidateSize = () => {
    if (!ref.current) return

    const margin = 4/*px*/ * (4/*clues*/ + 1)
    const cellInnerWidth = (ref.current.offsetWidth - margin) / 4
    const ratio = cellInnerWidth / DEFAULT_CELL_WIDTH
    const fontSize = Math.max(MIN_FONT_SIZE, DEFAULT_FONT_SIZE * ratio)
    setFontSize(`${fontSize}px`)
  }

  useLayoutEffect(() => {
    // init
    invalidateSize()
    // listener
    window.addEventListener('resize', invalidateSize)
    return () => window.removeEventListener('resize', invalidateSize)
  }, [])


  /* -------------------------
      INITIALIZE GRID
  ------------------------- */

  /**
   * The first time we enter the component, we build an
   * array of "clues to render" that we're going to play
   * with during the grid's lifetime.
   */
  if (!cluesToRender.length) {
    setCluesToRender(
      shuffleArray(arrayOfInts(16)).map((uid, idx) => {
        const groupIdx = Math.floor(uid / 4)
        const clueIdx = uid % 4
        // Make sure the object is immutable!
        return {
          uid,
          groupIdx,
          label: groups[groupIdx].clues[clueIdx],
          gridX: idx % 4,
          gridY: Math.floor(idx / 4),
          selected: false,
          found: false,
          errorCount: 0,
        }
      }),
    )
  }

  useEffect(() => {
    if (!foundGroupIds) return

    let i = -1
    setCluesToRender(
      cluesToRender.map(clue => {
        if (foundGroupIds.includes(clue.groupIdx)) {
          return {
            ...clue,
            gridX: clue.uid % 4,
            gridY: foundGroupIds.indexOf(clue.groupIdx),
            selected: false,
            found: true,
          }
        } else {
          i++
          const idx = foundGroupIds.length * 4 + i
          return {
            ...clue,
            gridX: idx % 4,
            gridY: Math.floor(idx / 4),
          }
        }
      }),
    )

  }, [foundGroupIds])


  /* -------------------------
      CLICK HANDLER
  ------------------------- */

  const onClueClick = (uid:number) => {
    const idx = cluesToRender.findIndex(c => c.uid === uid)
    if (idx < 0) return

    let clues = cluesToRender
    const clue = clues[idx]

    if (clue.selected) {
      // unselect the clue
      clues = update(clues, { [idx]: { $merge: { selected: false } } })
    } else {
      // select the clue
      clues = update(clues, { [idx]: { $merge: { selected: true } } })

      // when 4 clues are selected... check if found or failed
      const selectedClues = clues.filter(c => c.selected)
      if (selectedClues.length >= 4) {
        const groupIds = selectedClues.map(c => c.groupIdx)
        if (groupIds.every(id => id === groupIds[0])) {
          // Found a group!

          onGroupFound(groupIds[0])

          const updates = {}
          selectedClues.map(clue => {
            const idx = clues.indexOf(clue)
            updates[idx] = { $merge: { selected: false, found: true } }
          })
          clues = update(clues, updates)

        } else {
          // Failed attempt

          onFailedAttempt()

          const updates = {}
          selectedClues.map(clue => {
            const idx = clues.indexOf(clue)
            updates[idx] = { $merge: { selected: false, errorCount: clue.errorCount + 1 } }
          })
          clues = update(clues, updates)

        }
      }
    }

    setCluesToRender(clues)

    // // if already checked
    // if (selectedClueIds.includes(clue.uid)) {
    //   clue.selected = false
    //   clueIds = selectedClueIds.filter(uid => uid != clue.uid)
    // } else {
    //   clue.selected = true
    //   clueIds = [...selectedClueIds, clue.uid]
    // }

    // if (clueIds.length < 4) {
    //   setSelectedClueIds(clueIds)
    // }
    // // guessing a full group...
    // else {
    //   const groupIds = clueIds.map(uid => getCoordByUid(uid).groupIdx)
    //   const found = groupIds.every(id => id === groupIds[0])

    //   // guessed right !
    //   if (found) onGroupFound(groupIds[0])
    //   // guessed wrong...
    //   else onFailedAttempt()

    //   // deselect all cules
    //   setSelectedClueIds([])
    // }

    // console.log('test', clueIds)
    // setCluesToRender(cluesToRender)
  }

  /* --------------------------------------------------
      RENDER
  -------------------------------------------------- */

  return <>

    <div
      ref={ref}
      style={{ fontSize }}
      className={ 'c-Grid groups-found-' + foundGroupIds.length }
    >

      {cluesToRender.map((clue) =>

        <GridCell
          groupIdx={clue.groupIdx}
          key={clue.uid}
          label={clue.label}
          gridX={clue.gridX}
          gridY={clue.gridY}
          selected={clue.selected}
          found={clue.found}
          errorCount={clue.errorCount}
          onClick={() => onClueClick(clue.uid)}
        ></GridCell>,

      )}

    </div>

  </>
}
