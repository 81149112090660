import { createSlice, PayloadAction } from '@reduxjs/toolkit'


/* --------------------------------------------------

    STATE

-------------------------------------------------- */

export interface ScoresState {
  [gridId:string]: GridScore
}

export interface GridScore {
  won:boolean
  date:string
  attempts:[number, number, number]
}

const initialState:ScoresState = {}


/* --------------------------------------------------

    SLICE

-------------------------------------------------- */

export const scoresSlice = createSlice({
  name: 'scores',
  initialState,
  reducers: {
    setGridScore: (state, action:PayloadAction<{ gridId:number, score:GridScore }>) => {
      // Never override the first try at solving a grid
      if (!state[action.payload.gridId]) {
        state[action.payload.gridId] = action.payload.score
      }
    },
  },
})

export const { setGridScore } = scoresSlice.actions
export default scoresSlice.reducer
