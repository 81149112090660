import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'
import { Theme } from '../utils'


/* --------------------------------------------------

    STATE

-------------------------------------------------- */

export interface UserState {

  /**
   * Unique id used to anonymously store and combine
   * usage data server side. The UUID V4 agorythm allows
   * us to create uids client side with a very very very
   * low chance of collision. Finger crossed!
   */
  uid:string

  /**
   * UI light/dark mode preference (auto by default).
   */
  theme:Theme

}

const initialState:UserState = {
  uid: uuidv4(),
  theme: Theme.LIGHT,
}


/* --------------------------------------------------

    SLICE

-------------------------------------------------- */

export const scoresSlice = createSlice({
  name: 'scores',
  initialState,
  reducers: {
    setColorMode: (state, action:PayloadAction<Theme>) => {
      state.theme = action.payload
    },
  },
})

export const { setColorMode } = scoresSlice.actions
export default scoresSlice.reducer
