import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import globalReducer from './globalSlice'
import scoresReducer from './scoresSlice'
import userReducer from './userSlice'
import gridReducer from './gridSlice'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import { stat } from 'fs'
import createMigrate from 'redux-persist/es/createMigrate'
import { Theme } from '../utils'

/* -------------------------
    REDUCERS
------------------------- */

// Combined to be used by `redux-persist`
const reducers = combineReducers({
  global: globalReducer,
  user: userReducer,
  scores: scoresReducer,
  grid: gridReducer,
})


/* -------------------------
    PERSIST
------------------------- */

const persistMigrations = {
  // 1: (state) => {
  //   return {
  //     ...state,
  //     user: {
  //       ...state.user,
  //       theme: Theme.AUTO,
  //     },
  //   }
  // },
}

const persistedReducer = persistReducer<ReturnType<typeof reducers>>({
  key: 'root',
  storage, // persist the data in the local storage
  whitelist: ['user', 'scores'], // don't persist anything else
  stateReconciler: autoMergeLevel2, // merge policy with initialState
  version: 0, // structure version, works with `migrate`
  migrate: createMigrate(persistMigrations, { debug: false }),
}, reducers)


/* -------------------------
    LOGGER
------------------------- */

const logger = createLogger({
  collapsed: true,
})


/* -------------------------
    STORE & EXPORT
------------------------- */

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk, logger],
})

// Enable the persistance
export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

// Typed replacements of `useDispatch` and `useSelector` to use throughout the app
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
