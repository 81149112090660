import './App.scss'
import React, { useEffect } from 'react'
import { Header } from './components/Header'
import { Loading } from './components/Loading'
import { Archives } from './pages/Archives'
import { Route, Routes } from 'react-router-dom'
import { About } from './pages/About'
import { Game } from './pages/Game'
import { useAppDispatch, useAppSelector } from '../redux'
import { fetchMainJSON } from '../redux/globalSlice'
import { LoadingStatus, Theme } from '../utils'

export function App() {
  const dispatch = useAppDispatch()


  /* -------------------------
      STATE & PARAMS
  ------------------------- */

  // from redux
  const loadingStatus = useAppSelector((state) => state.global.loadingStatus)
  const theme = useAppSelector((state) => state.user.theme)


  useEffect(() => {
    let applyTheme = theme
    if (applyTheme === Theme.AUTO) {
      applyTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? Theme.DARK : Theme.LIGHT
    }
    document.documentElement.setAttribute('theme', applyTheme)
  }, [theme])

  /* -------------------------
      INIT
  ------------------------- */

  useEffect(() => {
    dispatch(fetchMainJSON())
  }, [])


  /* -------------------------
      RENDER : LOADING/ERROR
  ------------------------- */

  if (loadingStatus === LoadingStatus.LOADING || loadingStatus === LoadingStatus.IDLE) {
    return <Loading></Loading>
  }

  if (loadingStatus === LoadingStatus.ERROR) {
    return <div className="global-error">
      <span className="emoji">💩</span>
      Désolé,<br/>
      une erreur s&apos;est produite.
    </div>
  }


  /* -------------------------
      RENDER
  ------------------------- */

  return <>

    <Header></Header>

    <Routes>
      <Route path="/" element={<Archives />} />
      <Route path="/:gridId" element={<Game />} />
      <Route path="about" element={<About />} />
    </Routes>

  </>
}
