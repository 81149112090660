import './FoundGroup.scss'
import React, { useState } from 'react'
import { GridGroup } from '../../utils/interfaces'
import { GridCell } from './GridCell'

interface Props {
  group:GridGroup
  reasonToggleVisible:boolean
}

export const FoundGroup = ({ group, reasonToggleVisible = false }:Props) => {

  const [showReason, setConnectionVisible] = useState<boolean>(false)

  const onReasonClick = (event) => {
    if (showReason) return
    event.stopPropagation()
    setConnectionVisible(!showReason)
  }

  return <>

    <div className={'c-FoundGroup ' + (showReason ? 'opened' : '')}>

      <div className="clues">
        {group.clues.map((clue, i) =>

          <GridCell
            key={i}
            label={clue}
            gridX={i}
            gridY={0}
            interactive={false}
          ></GridCell>,

        )}
      </div>

      { reasonToggleVisible ? <>

        <div className="reason" onClick={onReasonClick}>
          { showReason ? <>

            <h2>{group.connection}</h2>
            <p>{group.description}</p>

          </> : <>

            Voir la connexion

          </> }
        </div>

      </> : <></> }

    </div>

  </>
}
