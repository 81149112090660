import './Archives.scss'
import React from 'react'
import { Link } from 'react-router-dom'
import { useAppSelector } from '../../redux'

export const Archives = () => {


  /* -------------------------
      STATE
  ------------------------- */

  // from redux
  const latestGridId = useAppSelector((state) => state.global.latestGridId)
  const gridRanges = useAppSelector((state) => state.global.gridRanges)


  /* -------------------------
      RENDER
  ------------------------- */

  return <>

    <main id="main" className="p-Archives">

      <h2>Niveaux</h2>

      { gridRanges.map((range, i) => <ul key={'ul' + i}>

        { [...Array(range[1] - range[0] + 1)].map((x, i) => {
          i = range[0] + i
          return <li key={i}>
            <Link to={'/' + i}>{i}</Link>
          </li>
        })}

      </ul>)}

    </main>

  </>

}
