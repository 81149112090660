// import './About.scss'
import React from 'react'

export const About = () => {
  return <>

    <main id="main" className="p-About">

      <h2>Règles</h2>
      <h3>But du jeu</h3>
      <p>
        Pour gagner, vous devez identifier les quatre groupes
        de la grille.
      </p>
      <p>
        Lorsque la grille est terminée, vous pouvez essayer de
        deviner/confirmer le lien entre les indices de chaque
        groupe.
      </p>
      <h3>Tentatives</h3>
      <p>
        Cliquez sur 4 indices pour valider un groupe.
      </p>
      <p>
        Mais attention, même si vous pouvez essayer autant de
        fois que vous le souhaitez pour trouver le premier groupe,
        vous n'avez droit qu'à 8 tentatives pour le second et 3
        pour les deux derniers groupes.
      </p>
      <h3>Indices et pièges</h3>
      <p>
        Les indices d'un même groupe partagent quelque chose en
        commun (sens, usage, référence, relation, etc.). Mais
        attention aux pièges, il n'y a qu'une seule combinaison
        valable pour compléter chaque grille !
      </p>

      <h2>A propos</h2>
      <p>
        Le principe de base du jeu est librement inspiré d'un des
        tour du jeu télévisé anglais "Only Connect".
      </p>

    </main>

  </>

}
