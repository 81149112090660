import './Header.scss'
import React from 'react'
import { Link } from 'react-router-dom'

export const Header = () => {

  return <>

    <header className="c-Header">

      <Link className="logo" to="/"><span className="sr-only">La grille</span></Link>

      <div className="left">
        <Link className="archives" to="/" title="Archives"><span className="sr-only">Archives</span></Link>
      </div>

      <div className="right">
        <div className="version-tag">alpha 1</div>
        <Link className="about" to="/about" title="À propos"><span className="sr-only">À propos</span></Link>
      </div>

    </header>

  </>

}
