/**
 * Returns an array of integers, staring from 0, of the size request.
 */
export function arrayOfInts(size:number):Array<number> {
  return Array.from(Array(size).keys())
}

/**
 * Shuffle an array using the Fisher-Yates algorithm.
 */
export function shuffleArray(array:Array<any>) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    const temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
  return array // only for chaining (we change the original ref)
}

export enum LoadingStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  ERROR = 'error',
  LOADED = 'loaded',
}

export enum Theme {
  AUTO = 'auto',
  DARK = 'dark',
  LIGHT = 'light',
}
