import './Game.scss'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../redux'
import { fetchGrid } from '../../redux/gridSlice'
import { LoadingStatus } from '../../utils'
import { Loading } from '../components/Loading'
import { Grid } from '../components/Grid'
import { FoundGroup } from '../components/FoundGroup'

const GROUPS_FOUND_LABEL = [
  'Identifiez les 4 groupes de la grille.',
  'Bravo ! Vous avez trouvé un groupe.',
  'Bravo ! Vous avez trouvé deux groupes.',
  'Bravo ! Vous avez trouvé tous les groupes.\n Essayez maintenant de deviner les connexions.',
]

export const Game = () => {
  const dispatch = useAppDispatch()


  /* -------------------------
      STATE
  ------------------------- */

  // from redux
  const latestGridId = useAppSelector((state) => state.global.latestGridId)
  // TODO: Sortir de la slice pour mettre en local ?
  const loadingStatus = useAppSelector((state) => state.grid.loadingStatus)
  const gridGroups = useAppSelector((state) => state.grid.groups)

  // from the router
  const { gridId = latestGridId } = useParams()

  // local state
  const [foundGroupIds, setFoundGroupIds] = useState<Array<number>>([])
  const [totalAttempts, setTotalAttempts] = useState<number>(0)
  const [attemptsLeft, setAttemptsLeft] = useState(Infinity)


  /* -------------------------
      INIT
  ------------------------- */

  useEffect(() => {
    // load the grid
    dispatch(fetchGrid(gridId as number))
  }, [])


  /* -------------------------
      LISTENERS
  ------------------------- */

  const onGroupFound = (groupId) => {
    if (!foundGroupIds.includes(groupId)) {
      // add the new group the founds ones
      const newVal = [...foundGroupIds, groupId]
      // finds the last group automatically
      if (newVal.length >= gridGroups.length - 1) {
        newVal.push(Array(gridGroups.length).findIndex((x, i) => !newVal.includes(i)))
      }

      setFoundGroupIds(newVal)

      const numGroupsLeft = gridGroups.length - newVal.length
      if (numGroupsLeft <= 2) setAttemptsLeft(3)
      else if (numGroupsLeft <= 3) setAttemptsLeft(8)
      else setAttemptsLeft(Infinity)
    }
  }

  const onFailedAttempt = () => {
    setTotalAttempts(totalAttempts + 1)
    setAttemptsLeft(attemptsLeft - 1)
  }


  /* -------------------------
      LOADING/ERROR STATES
  ------------------------- */

  if (loadingStatus === LoadingStatus.LOADING || loadingStatus === LoadingStatus.IDLE) {
    return <main id="main"><Loading></Loading></main>
  }

  if (loadingStatus === LoadingStatus.ERROR) {
    return <div className="global-error">
      <span className="emoji">💩</span>
      Désolé,<br/>
      une erreur s&apos;est produite.
    </div>
  }


  /* -------------------------
      RENDER
  ------------------------- */

  return <>

    <main id="main" className="p-Game">

      <div className="grid-number">No. {gridId}</div>

      {/* { foundGroupIds.length < gridGroups.length && <> */}

      <div className="attempts-left">
        <div className={'counter ' + (!isFinite(attemptsLeft) ? 'infinite' : '')}>
          {attemptsLeft !== Infinity ? attemptsLeft : 8}
        </div>
        <div className="text">Tentatives illimitées pour<br/> trouver le premier groupe.</div>
      </div>

      <Grid
        groups={gridGroups}
        foundGroupIds={foundGroupIds}
        onGroupFound={onGroupFound}
        onFailedAttempt={onFailedAttempt}
      ></Grid>

      {/* </> } */}

      { foundGroupIds.length >= 4 && <>

        { foundGroupIds.map(groupIdx =>

          <FoundGroup
            reasonToggleVisible={foundGroupIds.length === gridGroups.length}
            key={groupIdx.toString()}
            group={gridGroups[groupIdx]}
          ></FoundGroup>,

        )}

      </> }

    </main>

  </>

}
