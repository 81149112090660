import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { GridGroup } from '../utils/interfaces'
import { LoadingStatus } from '../utils'


/* --------------------------------------------------

    STATE

-------------------------------------------------- */

export interface GridState {
  loadingStatus:LoadingStatus
  gridId: 0
  groups:Array<GridGroup>
}

const initialState:GridState = {
  loadingStatus: LoadingStatus.IDLE,
  gridId: 0,
  groups: [],
}


/* --------------------------------------------------

    THUNKS

-------------------------------------------------- */

export const fetchGrid = createAsyncThunk(
  'grid/fetchGrid',
  async (gridId:number) => {
    const response = await fetch(`${process.env.API_URL}/grid${gridId}.json`).then(response => response.json())
    return response
  },
)

const fetchGrid_reducer = (builder) => builder
  .addCase(fetchGrid.pending, (state, action) => {
    state.loadingStatus = LoadingStatus.LOADING
  })
  .addCase(fetchGrid.rejected, (state, action) => {
    state.loadingStatus = LoadingStatus.ERROR
  })
  .addCase(fetchGrid.fulfilled, (state, action) => {
    state.groups = action.payload.groups
    state.loadingStatus = LoadingStatus.LOADED
  })


/* --------------------------------------------------

    SLICE

-------------------------------------------------- */

export const gridSlice = createSlice({
  name: 'grid',
  initialState,
  reducers: {

  },
  extraReducers: builder => {
    fetchGrid_reducer(builder)
  },
})

export default gridSlice.reducer
