import './GridCell.scss'
import React, { useEffect, useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'

interface Props {
  groupIdx:number
  label:string
  gridX:number
  gridY:number
  selected:boolean
  found:boolean
  errorCount:number
  onClick:(event) => void
}

export const GridCell = ({
  groupIdx,
  label,
  gridX,
  gridY,
  selected = false,
  found = false,
  errorCount = 0,
  onClick = () => void 0,
}:Props) => {

  const nodeRef = useRef(null)

  const classNames = ['c-GridCell']
  classNames.push('grid-x-' + gridX)
  classNames.push('grid-y-' + gridY)
  if (!found) classNames.push('interactive')
  if (selected) classNames.push('selected')
  if (found) classNames.push('found', 'group-' + groupIdx)

  const [showError, setShowError] = useState(false)

  useEffect(() => {
    if (!showError && errorCount) setShowError(true)
  }, [errorCount])

  return <>

    <CSSTransition
      nodeRef={nodeRef}
      in={showError}
      timeout={500}
      exit={false}
      classNames="error"
      onEntered={() => setShowError(false)}
    >
      <div ref={nodeRef} className={classNames.join(' ')} onClick={(event) => !found && onClick(event)}>
        <div className="label">{label}</div>
      </div>
    </CSSTransition>
  </>

}
